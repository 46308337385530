.about-us-section {
  background-color: #ffd957;
  position: relative;
  padding: 100px 0;
  text-align: center;
}

.about-us-container {
  position: relative;
  width: 80%;
  margin: 50px auto;
}

.aboutus-container {
  display: flex;
  position: relative;
  width: 98%;
  margin: 50px auto;
  font-size: 23px;
  height: auto; /* Height'i otomatik yapıyoruz */
  flex-wrap: wrap; /* İçeriğin mobilde alt alta geçmesini sağlıyoruz */
}

.hak {
  font-size: 40px;
  margin-bottom: 20px; /* Mobilde biraz daha boşluk bırakıyoruz */
}

.highlighted-text {
  font-size: 48px;
  font-weight: bold;
  color: white;
  background-color: #2b2b2b;
  display: inline-block;
  padding: 10px 40px;
  border-radius: 5px;
}

.icon-left, .icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.icon-left {
  left: 10%;
}

.icon-right {
  right: 10%;
}

.icon-left img, .icon-right img {
  width: 100px;
  height: auto;
}

/* Section Two: Görsel ve metin aynı satırda olacak şekilde düzenleme */
.about-us-section-two {
  background-color: #fff;
  padding: 80px 0;
}

.about-us-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.left-column, .right-column {
  flex: 1;
  padding: 20px;
}

.about-heading {
  font-size: 48px;
  font-weight: bold;
  color: #2b2b2b;
  line-height: 1.2;
}

.about-description {
  font-size: 18px;
  color: #767676;
  line-height: 1.8;
}

/* Görsel ve metin yan yana */
.about-us-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.about-us-image img {
  height: 300px;
  max-width: 100%; /* Resmin taşmaması için */
}

.about-us-content {
  text-align: left;
}

.digital-transformation {
  text-align: center;
  margin-top: 60px;
  background: #fff;
  height: 500px;
}

.digital-transformation h2 {
  font-size: 2.5rem;
  color: #333;
  padding: 80px;
}

.steps-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 20px;
}

.step img {
  width: 70px;
  height: 70px;
  background-color: #ffd957;
  padding: 20px;
  border-radius: 8px;
}

.step-number {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 10px;
}

.step-description {
  font-size: 1.5rem;
  margin-top: 5px;
  color: #333;
}

.arrow {
  font-size: 2rem;
  color: #333;
  margin: 0 20px;
}

/* Responsive design */
@media (max-width: 768px) {
  .about-us-content {
    flex-direction: column;
    text-align: center;
    padding: 0 10px; /* Mobilde kenarlardan daha az boşluk bırakıyoruz */
  }

  .about-us-image {
    flex-direction: column;
    margin-bottom: 20px; /* Görsel ile metin arasında daha fazla boşluk */
  }

  .steps-container {
    flex-direction: column;
  }

  .arrow {
    display: none;
  }

  .about-us-image img {
    max-width: 100%;
    height: auto;
  }

  .about-heading {
    font-size: 36px; /* Başlık boyutunu küçültüyoruz */
  }

  .about-description {
    font-size: 16px; /* Yazı boyutunu biraz küçültüyoruz */
  }

  .hak {
    font-size: 32px; /* 'Hakkımızda' başlığını küçültüyoruz */
    margin-bottom: 10px;
  }

  .highlighted-text {
    font-size: 36px; /* 'Biz Kimiz' metni mobilde daha küçük */
  }

  .icon-left img, .icon-right img {
    width: 70px; /* Simge boyutlarını küçültüyoruz */
  }

  .digital-transformation {
    height: auto;
    padding: 50px 20px; /* Mobilde padding'i ayarlıyoruz */
  }

  .digital-transformation h2 {
    font-size: 2rem; /* Başlık boyutunu küçültüyoruz */
  }
}

@media (max-width: 480px) {
  .aboutus-container {
    flex-direction: column;
    font-size: 18px; /* Küçük ekranlarda yazı boyutunu küçültüyoruz */
  }

  .about-us-content {
    flex-direction: column;
  }

  .about-us-image {
    margin-bottom: 20px;
  }

  .about-heading {
    font-size: 28px; /* Daha küçük ekranlarda başlık boyutunu küçültüyoruz */
  }

  .hak {
    font-size: 28px; /* 'Hakkımızda' başlığını daha da küçültüyoruz */
  }

  .highlighted-text {
    font-size: 28px; /* 'Biz Kimiz' metnini daha da küçültüyoruz */
  }

  .icon-left img, .icon-right img {
    width: 50px; /* Simge boyutlarını küçültüyoruz */
  }
}