.grow-section{
    display: flex;
    padding: 0px 50px;
    width: auto;
    background-color: #ffd957;
    position: relative;
    text-align: center;
  }

  .grow-section h2{
    font-size: 2.5rem;
    font-weight: bold;
    color: #2b2b2b; /* SarÄ± renk baÅŸlÄ±k */
    line-height: 1.2;
  }

  .grow-section button{
    display: inline-block;
    padding: 12px 25px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .dark-button,
  .light-button {
    padding: 20px 40px;
    font-size: 1rem;
    cursor: pointer;
    margin: 45px;
    border-radius: 3px;

  }
  
  .dark-button {
    background-color: #2c2c2c;
    color: #ffd957;
  }
  
  .dark-button:hover {
    background-color: #ffd957; /* Hover durumunda sarıya geçiş */
    color: #2c2c2c;
    border-color: 2px solid #2c2c2c;
  }
  
  .light-button {
    background-color: white;
    border: 2px solid #2c2c2c;
    color: #2c2c2c;
  }
  
  .light-button:hover {
    background-color: #2c2c2c;
    color: #ffd957;
  }
  
