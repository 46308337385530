/* src/styles/Homepage.css */

/* New awards section */
.awards-section {
    display: flex;
    justify-content: space-around;
    padding: 50px 20px;
    background-color: #fff;
    width: 100%;
  }
  
  .award-column {
    width: 30%;
    align-items: center;
    text-align: left;
  }
  
  .award-column h2 {
    font-size: 1.8rem;
    color: #000;
    margin-bottom: 15px;
  }
  
  .award-column p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
  }
  
  .award-icon {
    width: 50px;
    height: auto;
    margin-bottom: 15px;
  }
  
  .award-column h3 {
    font-size: 1.4rem;
    color: #000;
    margin: 10px 0;
  }
  
  .award-column hr {
    border: 1px solid #333;
    margin: 10px 0;
  }
  
  .award-column p:last-of-type {
    font-size: 1rem;
    color: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .awards-section {
      flex-direction: column;
      align-items: center;
    }
  
    .award-column {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  
    .award-icon {
      width: 40px;
    }
  }