
/* Contact section styling */
.contact-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding: 50px 20px;
    background-color: #f9f9f9;
    
  }
  
  .contact-info {
    background-color: #fff;
    margin-top: 100px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .contact-info h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-info p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
  }
  
  .contact-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  
  .contact-details h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .contact-details p a {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .contact-details p a:hover {
    color: #0056b3;
  }
  
  .contact-form {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .contact-form h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
    display: block;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    color: #555;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    outline: none;
    border-color: #007BFF;
  }
  
  .submit-btn {
    display: inline-block;
    padding: 12px 25px;
    background-color: #007BFF;
    color: #fff;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .contact-section {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  
    .contact-info, .contact-form {
      padding: 20px;
    }
  }