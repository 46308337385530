/* Page title section */
.what-we-do-section  {
    text-align: center;
    background-color: #ffd957;
    color: white;
   

  }
  .highlighted-text{
    margin: 150px auto;
  }  
  
 .highlighted-text span{
    font-size: 48px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    display: inline-block;
    background-color: #2b2b2b;
  }

 
  /* Services grid section */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0px 0px;
    justify-items: center;
    background-color: #fff;
  }
  
  .service-box {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
    width: 350px;
  }
  
  .service-box:hover {
    transform: translateY(-10px);
  }
  
  .service-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .service-box h2 {
    font-size: 24px;
    color: #333;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-bottom: 15px;
  }
 
  .learn-more-button {
    padding: 15px 20px;
    background-color: #333;
    color: white;
    border-radius: 2px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 3px solid #ffd957 !important;
  }
  
  .learn-more-button a {
    text-decoration: none;
    color: #fff;
  }
  
  .learn-more-button:hover {
    background-color: #555;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  
    .service-box {
      margin: 0 auto;
    }
  }