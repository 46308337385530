/* Genel yapÄ± */
.intro-section {
    display: flex;

    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
    background-color: #fff;
    width: fit-content;
  }
  
  /* Sol bÃ¶lÃ¼m (BÃ¼yÃ¼k baÅŸlÄ±k) */
  .intro-left {
    flex: 1;
    padding-right: 20px;
  }
  
  .intro-left h1 {
    font-size: 3.5rem;
    font-weight: bold;
    color: #ffd957; /* SarÄ± renk baÅŸlÄ±k */
    line-height: 1.2;
  }
  
  .intro-left h1 br {
    display: none; /* Responsive tasarÄ±m iÃ§in; mobilde satÄ±r kÄ±rma kontrolÃ¼ */
  }
  
  /* SaÄŸ bÃ¶lÃ¼m (AÃ§Ä±klama metni) */
  .intro-right {
    flex: 1.5;
    color: #2c2c2c;
  }
  
  .intro-right p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #7a7a7a; /* AÃ§Ä±klama metni iÃ§in daha aÃ§Ä±k bir gri */
  }
  
  .intro-right a {
    color: #1e90ff; /* Link rengi (mavi) */
    text-decoration: none;
  }
  
  .intro-right a:hover {
    text-decoration: underline;
  }
  
  /* Responsive (Mobil) */
  @media (max-width: 768px) {
    .intro-section {
      flex-direction: column;
      text-align: center;
    }
  
    .intro-left, .intro-right {
      padding: 20px 0;
    }
  
    .intro-left h1 {
      font-size: 2.5rem;
    }
  
    .intro-left h1 br {
      display: inline; /* Mobilde satÄ±r kÄ±rma aktif */
    }
  }

  .grow-section{
    padding: 0px 50px;
    width: auto;
  }