/* Genel bölüm stilini ayarlıyoruz */
.services-section {
    display: flex;
    justify-content: space-between; /* Kartları yatayda yaymak için */
    gap: 20px; /* Kartlar arasında boşluk */
    background-color: #fff;
    width: 100%;
    margin-top: 5px;
margin-bottom: 15px;
  }
  
  .service-card {
    background-color: #fff; /* Kartın arka plan rengi */
    border: 1px solid transparent; /* Başlangıçta transparan border */
    border-radius: 8px;
    padding: 50px;
    width: 30%;
    transition: border-color 0.3s ease; /* Border rengini smooth bir şekilde değiştirmek */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Hafif gölge */
    text-align: left;
  }
  
  /* Hover'da border'ı sarı yap */
  .service-card:hover {
    border-color: #ffd957; /* Sarı border hover'da */
  }
  
  .service-card .icon {
    margin-bottom: 20px;
  }
  
  .service-card .icon img {
    width: 50px; /* İkon boyutu */
  }
  
  .service-card h3 {
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  /* Responsive tasarım */
  @media (max-width: 768px) {
    .services-section {
      flex-direction: column; /* Mobilde kartları dikey sırala */
      gap: 30px; /* Kartlar arasındaki boşluk arttı */
    }
  
    .service-card {
      width: 100%; /* Mobilde kart genişliği tam olacak */
    }
  }