/* Genel yapı */
.strategic-section {
    padding: 50px ; /* Üst-alt boşluk, soldan ve sağdan daraltmak için 0 eklendi */
    background-color: #fff;
    display: flex;
    justify-content: center; /* Ortalama */
    align-items: center; /* Dikey ortalama */
    width: 100%;
  }
  
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%; /* İçeriği daraltma */
    max-width: 1200px; /* Maksimum genişlik */
    margin: 0 auto; /* Ortalanması için */
  }
  
  .strategic-left {
    flex: 1.5;
  }
  
  .strategic-right {
    flex: 1;
    display: flex;
    align-items: right;
    justify-content: center; /* Görselin ortalanması */
  }
  
  .strategic-right img {
    max-width: 100%; /* Görselin genişliğini sınırlama */
    height: auto;
  
  }
  
  /* Başlık stili */
  .strategic-left h1 {
    font-size: 2.8rem;
    font-weight: bold;
    color: #2c2c2c;
    margin-bottom: 30px;
    line-height: 1.3;
    align-items: start;
  }
  
  /* Liste ve ikonlar */
  .strategic-left ul {
    display: inline;
    padding-left: 0;
    margin-bottom: 30px;
    align-items: start;
  }
  
  .strategic-left li {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 15px;
    color: #7a7a7a;
    align-items: start;
    text-align: left;
  }
  
  .check-icon {
    font-size: 1.5rem;
    color: #ffd957;
    margin-right: 10px;
  }
  
  /* CTA butonu */
  .cta-button {
    padding: 15px 30px;
    float: left;
    background-color: #333;
    color: white;
    border:  3px solid #ffd957;
    border-radius: 2px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #555;
  }
  
  /* Responsive tasarım */
  @media (max-width: 768px) {
    .content {
        flex-direction: column;
        text-align: center;
        width: 90%; /* Mobil cihazlarda genişliği biraz daha arttırdık */
    }
  
    .strategic-left {
        margin-bottom: 30px;
    }
  
    .strategic-left h1 {
        font-size: 2.2rem;
    }
  
    .cta-button {
        width: 100%;
    }
  }