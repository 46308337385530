
/* src/styles/MediaPlanning.css */
.media-planning-section {
  padding: 80px 20px;
  background-color: white; /* Light gray background */
}

.media-planning-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.media-planning-text {
  flex: 1;
  padding-right: 40px;
}

.media-planning-text h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2b2b2b;
}

.media-planning-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
}

.media-planning-image {
  flex: 1;
  padding-left: 40px;
  text-align: right;
}

.media-planning-image img {
  max-width: 100%;
  height: 500px;
  border-radius: 8px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .media-planning-container {
    flex-direction: column;
  }

  .media-planning-text {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .media-planning-image {
    padding-left: 0;
    text-align: center;
  }
}
