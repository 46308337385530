.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffd957;
}

.logo img {
  height: 78px;
  margin: 5px;
  padding: 5px;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
  color: #333;
}

.navigation ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navigation ul li a:hover {
  color: #555;
}

.contact-button button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button button:hover {
  background-color: #555;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
}

/* Media Queries for Responsive */
@media (max-width: 768px) {
  .navigation ul {
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: #ffd957;
    width: 100%;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    z-index: 1;
  }

  .navigation.active ul {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .contact-button {
    display: none;
  }
}