/* General grid layout for large screens */
.references-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns for larger screens */
  gap: 40px;
  padding: 50px 20px;
  justify-items: center;
  background-color: #fff;
}

/* Reference box styling */
.reference-box {
  background-color: #f9f9f9;
  width: 250px;
  height: 250px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.reference-box:hover {
  transform: translateY(-5px);
}

.reference-box img {
  width: 150px;
  height: auto;
  margin-bottom: 15px;
  flex-shrink: 0;
}

.reference-box .spacer {
  flex-grow: 1;
}

.reference-button a {
  display: inline-block;
  background-color: #333;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.reference-button a:hover {
  background-color: #ffd957;
}

.reference-box::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #ffd957;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.reference-box:hover::after {
  transform: scaleX(1);
}

/* Responsive for tablets (768px and below) */
@media (max-width: 768px) {
  .references-grid {
    grid-template-columns: repeat(1, 1fr); /* 2 columns on tablets */
    gap: 30px;
  }

  .reference-box {
    width: 220px; /* Adjust width for smaller screens */
    height: 220px;
  }

  .reference-button a {
    font-size: 0.9rem; /* Slightly smaller text */
  }

  .reference-box img {
    width: 120px; /* Smaller logo for tablets */
  }
}

/* Responsive for mobile phones (480px and below) */
@media (max-width: 480px) {
  .references-grid {
    grid-template-columns: 1fr; /* Single column on phones */
    gap: 20px;
  }

  .reference-box {
    width: 180px; /* Smaller box on mobile */
    height: 180px;
  }

  .reference-button a {
    font-size: 0.8rem; /* Smaller button text on mobile */
  }

  .reference-box img {
    width: 100px; /* Smaller logo for phones */
  }
}