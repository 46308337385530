/* src/styles/Footer.css */

.footer-container {
  background-color: #fff; /* Daha açık gri arka plan */
  color: #444;
  padding: 50px 20px;
  font-family: "Poppins", sans-serif;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

.footer-column {
  flex: 1;
  min-width: 200px;
}

.footer-column h4 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #ffd957; /* Sarı başlıklar */
}

.footer-column ul {
  display: block;
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #bbb; /* Açık gri linkler */
  font-size: 16px;
}

.footer-column ul li a:hover {
  color: #ffd957; /* Hover efektinde sarı */
}

.footer-logo {
  max-width: 250px;
  margin-bottom: 20px;
}

.footer-bottom {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #666;
  color: #aaa; /* Alt kısım için açık gri */
}

/* Sosyal medya ikonları */
.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.social-icons a {
  color: #bbb;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ffd957; /* İkonlar hover'da sarıya dönüşür */
}

/* Responsive tasarım */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  .footer-column {
    align-items: center;
  }

  .social-icons {
    justify-content: center;
  }
}
