  /* References section */
  .references-section {
    text-align: center;
    background-color: #ffd957 !important; /* Yellow background color */
    padding: 50px 20px;
  }
  
  .references-section h2 {
    font-size: 50px  ;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000; /* Black text */
  
  }
  
  .references-section p {
    font-size: 20px  ;
    margin-bottom: 30px;
    color: #333;
  }
  
  .references-section .logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .references-section .logos img {
  height: 100px ;  
  margin: 10px;
  }
  
  
  
.highlighted-ref {
    background-color: #333;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 0px;
    font-size: 50px;
    font-weight: 500;
    position: relative; /* Çizgili border için */
  }
  
  .highlighted-ref::before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: -20px;
    bottom: -20px;
    border: 1.5px dashed #333; /* Çizgili (kesikli) border */
    pointer-events: none;
  }
  
  .highlighted-ref span {
    color: #fff; /* Metin rengi */
  }
  