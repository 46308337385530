
.hero-section {
    background-color: #ffd957; /* Arka plan rengi */
    padding: 80px 20px;
    text-align: center;
  }
  
  .hero-content h1 {
    font-size: 68px;
    color: #333;
    margin: 10px 0;
    font-weight: 700;
    position: relative; /* Çizgi eklemek için pozisyon gerekli */
  }
  
  .highlighted-text {
    background-color: #333;
    color: #fff;
    display: inline-block;
    padding: 0px 0px;
    margin: 0px 0px;
    font-size: 76px;
    font-weight: 500;
    position: relative; /* Çizgili border için */
  }
  
  .highlighted-text::before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: -20px;
    bottom: -20px;
    border: 1.5px dashed #333; /* Çizgili (kesikli) border */
    pointer-events: none;
  }
  
  .highlighted-text span {
    color: #fff; /* Metin rengi */
  }
  
  /* Responsive tasarım */
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 36px;
    }
  
    .highlighted-text {
      font-size: 28px;
    }
  }
  
  @media (max-width: 480px) {
    .hero-content h1 {
      font-size: 28px;
    }
  
    .highlighted-text {
      font-size: 22px;
    }
  }
  
  .hero-content p {
    font-size: 25px;
    margin-bottom: 30px;
  }
  
  .hero-buttons {
    gap: 50px;
  }
  
  .primary-btn,
  .secondary-btn {
    padding: 20px 40px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    margin: 45px;
  }
  
  .primary-btn {
    background-color: #2c2c2c;
    color: #ffd957;
  }
  
  .primary-btn:hover {
    background-color: #ffd957; /* Hover durumunda sarıya geçiş */
    color: #2c2c2c;
    border-color: 2px solid #2c2c2c;
  }
  
  .secondary-btn {
    background-color: transparent;
    border: 2px solid #2c2c2c;
    color: #2c2c2c;
  }
  
  .secondary-btn:hover {
    background-color: #2c2c2c;
    color: #ffd957;
  }
  
  /* Hero görüntüsü */
  .hero-illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    padding-top: 25px;
  }
  
  .hero-illustration img {
    max-width: 80%;
    height: auto;
    padding-top: 10px;
  }
  
  /* Arka plandaki animasyon kağıt uçak */
  .hero-section::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 80%;
    width: 100px;
    height: 100px;
    background-image: url("../images/paperplaneL.png");
    background-repeat: no-repeat;
    background-size: contain;
    animation: fly 5s infinite linear;
  }
  
  @keyframes fly {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-150px, -150px);
    }
  }
  /* Yeni küçük başlık ve butonlar */
  .hero-sub-content h2 {
    font-size: 2.0rem;
    font-weight: 500;
    color: #333; /* Başlık rengi */
    margin-bottom: 20px;
  }
  
  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .hero-buttons .dark-button {
    background-color: #333;
    color: #fff;
    padding: 12px 30px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero-buttons .dark-button:hover {
    background-color: #555;
  }
  
  .hero-buttons .light-button {
    background-color: #fff;
    color: #333;
    padding: 12px 30px;
    border: 2px solid #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .hero-buttons .light-button:hover {
    background-color: #f0f0f0;
    color: #000;
  }
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 2rem;
    }
  
    .hero-sub-content h2 {
      font-size: 1.5rem;
      color:white;
    }
  
    .hero-buttons {
      flex-direction: column;
      gap: 10px;
    }
  }